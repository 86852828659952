/**
 * Convert SAST date/time string to ISO 8601 before creating date object.
 * This gives us accurate timezones which are important for SSR.
 *
 * NB: Drawback is that it's hardcoded to only work for dates in the following format:
 * `2020-01-01[ 08:00:00]` (time optional)
 *
 * The BE now provides ISO 8601 standard dates for all orders, but not for products or cart items
 * TODO: Look into getting the remaining dates in ISO 8601 from the BE
 *
 * The function now caters for both date formats
 *
 * @param isoDate string
 * @param timezoneOffset string
 */
export const dateIsoToObject = (
  isoDate: string,
  timezoneOffset = '+02:00'
): Date => {
  if (isoDate.includes('T')) {
    return new Date(isoDate);
  }

  return new Date(`${isoDate.replace(/ /, 'T')}${timezoneOffset}`);
};

const pad = (number: number, length = 2) =>
  number.toString().padStart(length, '0');

/**
 * Convert Date object to ISO string.
 *
 * @param date Date
 */
export const dateObjectToIso = (date: Date, withTime = true) => {
  let dateString = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`;

  if (withTime) {
    dateString += ` ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds()
    )}`;
  }

  return dateString;
};

export const dateDiff = (dateA: Date, dateB: Date) =>
  Math.floor(dateA.getTime() - dateB.getTime()) / 1000;

export const secBefore = (isoDate: string): number =>
  Math.max(dateDiff(dateIsoToObject(isoDate), new Date()), 0);

/**
 * Resulting object will have the correct year, month, day, hours, minutes, seconds in SAST...
 * but it will think it's in the local timezone not SAST.
 * This means that it CANNOT be used by the Intl.DateTimeFormatter
 */
export const currentDateInSast = () => {
  // get local timezone offset from UTC (+0200 would be -120)
  const offset = new Date().getTimezoneOffset();
  // get current date
  const date = new Date();

  // set the timestamp to UTC equivalent
  date.setTime(date.getTime() + offset * 60 * 1000);
  // set the timestamp to SAST equivalent
  date.setTime(date.getTime() + 2 * 60 * 60 * 1000);

  return date;
};

/**
 * Hardcoding the format to YYYY-MM-DD because the date object we use for comparison
 * can't be run through a proper formatter.
 */
export const isCurrentDate = (dates: string[]) => {
  const currentDate = currentDateInSast();
  const formatted = dateObjectToIso(currentDate, false);
  return dates.filter(date => date === formatted).length > 0;
};

/**
 * Check if the current date is within a given range, can also handle staff preview dates.
 * @param startDate string in the format YYYY-MM-DDTHH:MM:SSZ
 * @param endDate string in the format YYYY-MM-DDTHH:MM:SSZ
 * @param previewDate string in the staff preview format YYYYMMDD
 */
export const isDateInRange = (
  startDate: string,
  endDate: string,
  previewDate?: string
): boolean => {
  const dateToCheck = previewDate
    ? new Date(
        `${previewDate.slice(0, 4)}-${previewDate.slice(
          4,
          6
        )}-${previewDate.slice(6, 8)}T00:00:00Z`
      )
    : new Date();
  return dateToCheck >= new Date(startDate) && dateToCheck < new Date(endDate);
};
