import { Box, Grid } from 'components/box';
import { Heading, Text } from 'components/typography';
import { memo, useRef } from 'react';
import ClockIcon from 'icons/clock.svg';
import { css } from '@emotion/react';
import Color from '../../util/color';
import { ClientOnly } from 'components/_shared/widgets/render-control';
import config from '../../util/load-config';

export const SoldOutMask = memo(
  ({
    clock,
    isDark,
    headingFontSize,
    text,
    isOnSearch,
    isPromoDeal,
    price,
  }: {
    clock?: boolean;
    isDark?: boolean;
    headingFontSize?: (string | number | null)[];
    text?: boolean;
    isOnSearch?: boolean;
    isPromoDeal?: boolean;
    price?: string;
  }) => {
    /**
     * Unfortunately, the following display conditions need to be split and handled
     * individually to enable more control over this component from config, without
     * needing code changes. Necessary because marketing keeps changing their minds.
     */
    const showPromoDealOverlay =
      config.marketing.soldOutOverlay.showPromoDealOverlay && isPromoDeal;

    const showPriceOnOverlay =
      config.marketing.soldOutOverlay.showPriceForPromoDeals && price;

    // Show the heading when it's not a promo deal or when it is a promo deal and the price is shown
    const showSoldOutHeading =
      !showPromoDealOverlay || (showPromoDealOverlay && showPriceOnOverlay);

    const soldOutHeader = useRef(
      showPriceOnOverlay && showPromoDealOverlay
        ? `This was a ${price} deal`
        : config.soldOutHeaders[
            Math.floor(Math.random() * config.soldOutHeaders.length)
          ]
    ).current;

    return (
      <Grid
        gridRowGap="0"
        css={theme => css`
          ${isOnSearch &&
          css`
            border-radius: ${theme.radii[3]}px;
          `};
          background-color: ${Color(isDark ? '#E3E4E8' : 'white')
            .alpha(isDark ? 0.7 : 0.9)
            .string()};
          font-family: ${theme.fonts.header};
          font-weight: ${theme.fontWeights.bolder};
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 120;
          color: ${theme.colors.darkGrey};
          place-items: center;
        `}
      >
        <Box>
          {clock && <ClockIcon alt="clock" />}
          {text && (
            <>
              <ClientOnly>
                <>
                  {showPromoDealOverlay && (
                    <Heading
                      fontSize={headingFontSize || [5, 7]}
                      fontWeight="bolder"
                      color="darkGrey"
                      mt={[4, null, 5]}
                    >
                      UNREAL DEAL
                    </Heading>
                  )}
                  {showSoldOutHeading && (
                    <Heading
                      css={
                        showPromoDealOverlay
                          ? css`
                              text-transform: none;
                            `
                          : css`
                              text-transform: uppercase;
                            `
                      }
                      aria-label={soldOutHeader}
                      fontSize={
                        showPromoDealOverlay
                          ? [3, null, 4]
                          : headingFontSize || [6, null, 7]
                      }
                      fontWeight={showPromoDealOverlay ? 'bold' : 'bolder'}
                      color="darkGrey"
                      mt={showPromoDealOverlay ? 0 : [4, null, 5]}
                    >
                      {soldOutHeader}
                    </Heading>
                  )}
                </>
              </ClientOnly>
              <Text
                aria-label="This deal is sold out!"
                fontSize={[1, 2, 3]}
                css={theme => css`
                  margin: 5px 0 3rem;
                  @media ${theme.mediaQueries.tabletDown} {
                    line-height: 1.2em;
                    margin-bottom: 1rem;
                  }
                `}
              >
                This deal is sold out!
              </Text>
            </>
          )}
        </Box>
      </Grid>
    );
  }
);

export default SoldOutMask;
