/**
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm
 */
import {
  BillingAddress,
  CartPargoConfig,
  CartPaymentMethod,
  CartShippingMethod,
  GiftMessage,
  Money,
  ShippingAddress,
  TotalsDiscount,
  TotalsStoreCredit,
  TotalsTax,
} from 'types/types';

export interface CheckoutOptions {
  step?: number;
  id?: string;
  tax?: number;
  coupon?: string;
  code?: string;
  value?: number;
}

interface PaymentMethod {
  code: string;
  isSelected: boolean;
}

interface Totals {
  subtotal: Money | null;
  grandTotal: Money | null;
  shipping: Money | null;
  tax?: TotalsTax | null;
  discount?: TotalsDiscount;
  storeCreditUsed?: TotalsStoreCredit;
}

export interface CheckoutCartInterface {
  transaction_id?: string;
  items?: ProductPayload[];
  email?: string;
  paymentMethods?: PaymentMethod[];
  billingAddress?: BillingAddress;
  shippingAddress?: ShippingAddress;
  voucher?: string;
  totals?: Totals | null;
  quantity?: number;
  hasAlcohol?: boolean;
  isVirtual?: boolean;
  value?: number;
  tax?: number;
  shipping?: number;
  currency?: string;
  purchase?: {
    actionField?: {
      id?: string;
      revenue?: string;
      tax?: number;
      shipping?: string;
      affiliation?: string;
      coupon?: string;
      paymentMethod?: string;
    };
  };
  remove?: {
    actionField?: {
      step: CheckoutEvents.removeCart;
    };
  };
}

export interface ProductPayload {
  item_list_name?: string;
  currency?: string;
  item_name: string;
  item_id?: string;
  price: number;
  item_brand?: string;
  sku?: string;
  isClearanceSale?: boolean;
  permanentShop?: string;
  category?: string;
  // cart/order specific props
  quantity?: number;
  variants?: string;
}

export interface UserType {
  userID?: string;
  userStatus: string;
  subscribed: boolean | undefined;
}

export type Payload = EcommercePayload | UserPayload | PageViewPayload;
export type CheckoutEventStep = 0 | 1 | 2 | 3 | 4;

interface EcommerceEventType {
  currency?: string;
  tax?: number;
  value?: number;
  shipping?: number;
  transaction_id?: string;
  coupon?: string;
  products?: CartItemType[];
  promoView?: {
    promotions: PromotionInterface[];
  };
  actionField?: {
    id?: string;
    affiliation?: string;
    revenue?: string;
    tax?: number;
    shipping?: string;
    coupon?: string;
    paymentMethod?: string;
    step?: number;
    option?: string;
    list?: string;
  };
  items?: ProductPayload[];
  cartEvent?: {
    actionField?: {
      list: string;
    };
  };
}

interface CartItemType {
  item_name: string;
  item_id?: string;
  price: number;
  item_brand?: string;
  quantity?: number;
  variants?: string;
}

interface EcommercePayload {
  reason?: string;
  event: EventNamesEnum;
  ecommerce: EcommerceEventType;
  user?: UserType;
  eventCallback?: () => void;
  eventTimeout?: number;
  pageLocation?: string;
  pagePath?: string;
  pageTitle?: string;
}

interface UserPayload {
  event: EventNamesEnum;
  user?: UserType;
  eventCallback?: () => void;
  eventTimeout?: number;
}

interface PageViewPayload {
  event: string;
  pageLocation?: string;
  userStatus?: string;
  pagePath?: string;
  pageTitle?: string;
  user?: UserType;
  eventCallback?: () => void;
  eventTimeout?: number;
}

export interface CustomizableOptions {
  label: string;
  value: string;
}

export interface PromotionInterface {
  id: string;
  name?: string;
  creative?: string;
  position?: string;
}

export interface GTMCallbackOptions {
  callback?: () => void;
  yieldCallbackToGTM: boolean;
  eventTimeout: number;
}

export enum CheckoutEvents {
  openCart = 1,
  viewCart = 1,
  addAddress = 2,
  changeAddress = 2,
  removeCart = 2,
  paymentMethodSelected = 3,
  purchase = 4,
  successPurchase = 4,
}

export enum EventNamesEnum {
  addPaymentInfo = 'add_payment_info',
  addShippingInfo = 'add_shipping_info',
  addToCart = 'add_to_cart',
  viewCart = 'view_cart',
  openCart = 'open_cart',
  removeFromCart = 'remove_from_cart',
  productClick = 'select_item',
  paymentAttempt = 'payment_attempt',
  productImpression = 'view_item',
  promotionClick = 'promotion_click',
  beginCheckout = 'begin_checkout',
  checkout = 'checkout',
  checkoutOption = 'checkout_option',
  purchase = 'purchase',
  failedPurchase = 'failed_purchase',
  successPurchase = 'purchase',
  backToHomePageFromPermShop = 'back_to_home_page_from_perm_shop',
  quickAddToCartClick = 'quick_add_to_cart_click',
  quickAddToCartLTDClick = 'quick_add_to_cart_LTD_click',
  quickAddToCartAdded = 'quick_add_to_cart_added',
  quickAddToCartOptionsOpened = 'quick_add_to_cart_options_opened',
  quickAddToCartOptionsClosed = 'quick_add_to_cart_options_closed',
  changePaymentMethod = 'change_payment_method',
  pageLoad = 'page_load',
  addAddress = 'add_address',
  changeAddress = 'add_shipping_info',
  addVoucher = 'add_voucher',
  removeVoucher = 'remove_voucher',
  addWallet = 'add_wallet',
  removeWallet = 'remove_wallet',
  newsletterSubscribed = 'newsletter_subscribed',
  signInSuccess = 'sign_in_success',
  signUpSuccess = 'sign_up_success',
  payFlex = 'payflex',
  payuCreditCard = 'credit_card',
  eft = 'eft',
  snapScan = 'snapscan',
  zapper = 'zapper',
  ozow = 'ozow',
  eBucks = 'eBucks',
  payuDiscoveryMiles = 'discovery_miles',
  wirecardMobicred = 'mobicred',
  peachCreditCard = 'credit_card',
  stitchPayByBank = 'stitch_pay_by_bank',
  account = 'account',
  openSizeGuide = 'open_size_guide',
  viewSizeRecommendation = 'view_size_guide_recommendation',
  viewSizeImage = 'view_size_image',
  viewSizeHowToMeasure = 'view_size_how_to_measure',
}

export enum PageNamesEnum {
  homepage = 'Home Page',
  clearance = 'Clearance Page',
  productDetail = 'Product Detail Page',
  checkout = 'Checkout Page',
  myOrders = 'Order Page',
  myAccount = 'My Account Page',
  permanentShopsPage = 'Permanent Shops Page',
  giftVouchersPage = 'Gift Vouchers Page',
  unNamed = 'Unnamed Page',
}

export enum PageTitleEnum {
  '/' = 'Home',
  '/shops/everyday-essentials' = 'Everyday Essentials',
  '/about-discovery-miles' = 'About Discovery Miles',
  '/about-ebucks' = 'About Ebucks',
  '/about-onedayonly' = 'About OneDayOnly',
  '/about-returns' = 'About Returns',
  '/about-shipping' = 'About Shipping',
  '/about-us' = 'About Us',
  '/app-download' = 'App Download',
  '/bank-details' = 'Bank Details',
  '/become-a-supplier' = 'Become a Supplier',
  '/checkout' = 'Checkout',
  '/clearance-sale' = 'Clearance Sale',
  '/competitions' = 'Competitions',
  '/contact-us' = 'Contact Us',
  '/covid-19' = 'Covid 19',
  '/faq' = 'FAQ',
  '/my-account' = 'My Account',
  '/my-orders' = 'My Orders',
  '/one-night-only' = 'OneNightOnly',
  '/order-success' = 'Order Success',
  '/payment/pay' = 'Payment Pay',
  '/payment/redirect' = 'Payment Redirect',
  '/payment/response' = 'Payment Response',
  '/privacy' = 'Privacy',
  '/sell-with-us' = 'Sell with Us',
  '/subscribe' = 'Subscribe',
  '/terms-and-conditions' = 'Terms and Conditions',
  '/vodapay' = 'Vodapay Home',
  '/vodapay-clearance-sale' = 'Vodapay Clearance Page',
}

/**
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#action-types
 */
enum EcommerceActionTypesEnum {
  click = 'click',
  detail = 'detail',
  add = 'add',
  change = 'change',
  remove = 'remove',
  checkout = 'checkout',
  checkoutOption = 'checkout_option',
  purchase = 'purchase',
  refund = 'refund',
  promoClick = 'promo_click',
  paymentMethod = 'paymentMethod',
  view = 'view',
  open = 'open',
}

export enum SocialEnum {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
  VODAPAY = 'vodapay',
}

export const actionToStepMap: Record<
  EcommerceActionTypesEnum,
  CheckoutEventStep
> = {
  [EcommerceActionTypesEnum.promoClick]: 0,
  [EcommerceActionTypesEnum.click]: 0,
  [EcommerceActionTypesEnum.detail]: 0,
  [EcommerceActionTypesEnum.add]: 0,
  [EcommerceActionTypesEnum.remove]: 0,
  [EcommerceActionTypesEnum.checkout]: 1,
  [EcommerceActionTypesEnum.view]: 1,
  [EcommerceActionTypesEnum.open]: 1,
  [EcommerceActionTypesEnum.checkoutOption]: 2,
  [EcommerceActionTypesEnum.change]: 2,
  [EcommerceActionTypesEnum.paymentMethod]: 3,
  [EcommerceActionTypesEnum.purchase]: 4,
  [EcommerceActionTypesEnum.refund]: 4,
};

export const eventToActionMap: Record<string, string> = {
  [EventNamesEnum.addToCart]: EcommerceActionTypesEnum.add,
  [EventNamesEnum.beginCheckout]: EcommerceActionTypesEnum.checkout,
  [EventNamesEnum.removeFromCart]: EcommerceActionTypesEnum.remove,
  [EventNamesEnum.productClick]: EcommerceActionTypesEnum.click,
  [EventNamesEnum.productImpression]: EcommerceActionTypesEnum.detail,
  [EventNamesEnum.checkout]: EcommerceActionTypesEnum.checkout,
  [EventNamesEnum.quickAddToCartClick]: EcommerceActionTypesEnum.click,
  [EventNamesEnum.quickAddToCartLTDClick]: EcommerceActionTypesEnum.click,
  [EventNamesEnum.quickAddToCartAdded]: EcommerceActionTypesEnum.add,
  [EventNamesEnum.quickAddToCartOptionsOpened]: EcommerceActionTypesEnum.detail,
  [EventNamesEnum.viewCart]: EcommerceActionTypesEnum.view,
  [EventNamesEnum.openCart]: EcommerceActionTypesEnum.open,
  [EventNamesEnum.checkoutOption]: EcommerceActionTypesEnum.checkoutOption,
  [EventNamesEnum.purchase]: EcommerceActionTypesEnum.purchase,
  [EventNamesEnum.failedPurchase]: EcommerceActionTypesEnum.purchase,
  /**
   * NOTE: not sure what this is here for, but it ends being a duplicate of EventNamesEnum.purchase
   * which then throws a ts error. so disabling for now.
   */
  // [EventNamesEnum.successPurchase]: EcommerceActionTypesEnum.purchase,
  [EventNamesEnum.quickAddToCartOptionsClosed]: EcommerceActionTypesEnum.click,
  [EventNamesEnum.changePaymentMethod]: EcommerceActionTypesEnum.change,
  [EventNamesEnum.addAddress]: EcommerceActionTypesEnum.add,
  [EventNamesEnum.changeAddress]: EcommerceActionTypesEnum.change,
  [EventNamesEnum.addVoucher]: EcommerceActionTypesEnum.add,
  [EventNamesEnum.removeVoucher]: EcommerceActionTypesEnum.remove,
  [EventNamesEnum.addWallet]: EcommerceActionTypesEnum.add,
  [EventNamesEnum.removeWallet]: EcommerceActionTypesEnum.remove,
  [EventNamesEnum.payFlex]: EcommerceActionTypesEnum.paymentMethod,
  [EventNamesEnum.payuCreditCard]: EcommerceActionTypesEnum.paymentMethod,
  [EventNamesEnum.eft]: EcommerceActionTypesEnum.paymentMethod,
  [EventNamesEnum.snapScan]: EcommerceActionTypesEnum.paymentMethod,
  [EventNamesEnum.zapper]: EcommerceActionTypesEnum.paymentMethod,
  [EventNamesEnum.ozow]: EcommerceActionTypesEnum.paymentMethod,
  [EventNamesEnum.eBucks]: EcommerceActionTypesEnum.paymentMethod,
  [EventNamesEnum.payuDiscoveryMiles]: EcommerceActionTypesEnum.paymentMethod,
  [EventNamesEnum.wirecardMobicred]: EcommerceActionTypesEnum.paymentMethod,
  [EventNamesEnum.stitchPayByBank]: EcommerceActionTypesEnum.paymentMethod,
  /**
   * NOTE: not sure what this is here for, but it ends being a duplicate of EventNamesEnum.payuCreditCard
   * which then throws a ts error. so disabling for now.
   */
  // [EventNamesEnum.peachCreditCard]: EcommerceActionTypesEnum.paymentMethod,
};

export interface PayloadCart {
  id: string;
  items?: ProductPayload[];
  products?: ProductPayload[];
  email?: string;
  giftMessage?: GiftMessage;
  paymentMethods: CartPaymentMethod[];
  billingAddress?: BillingAddress;
  shippingAddress?: ShippingAddress;
  shippingMethods?: CartShippingMethod[];
  voucher?: string;
  totals: Totals | null;
  quantity: number;
  weight?: number;
  isVirtual?: boolean;
  hasAlcohol?: boolean;
  pargo?: CartPargoConfig;
  errors?: string[];
}

export interface GoogleTagInfo {
  sessionId?: string;
  clientId?: string;
}

export type SizeGuideInteractionEvent =
  | EventNamesEnum.openSizeGuide
  | EventNamesEnum.viewSizeRecommendation
  | EventNamesEnum.viewSizeImage
  | EventNamesEnum.viewSizeHowToMeasure;
